export const data = {
	"all": [
		{
			"name": {
				"ua": "Браслет-підвіс",
				"en": "Bracelet-pendant"
			},
			"description": {
				"ua": "Дерево. В комплекті вощений шнур 1 мм чорного кольору. Ручна робота, кожен виріб унікальний.",
				"en": "Wood. Black waxed cord included. Handcrafted. Each piece is unique. Wood."
			},
			"price": {
				"ua": 4500,
				"en": 125
			},
			"group": "OBJECT",
			"variations": [{
				"size": "21,5",
				"image": {
					"key": ""
				}
			},{
				"size": "22",
				"image": {
					"key": ""
				}
			},{
				"size": "23,4",
				"image": {
					"key": ""
				}
			},{
				"size": "25",
				"image": {
					"key": ""
				}
			}]
		},
		{
			"name": {
				"ua": "Гребінь для волося",
				"en": "Hair comb"
			},
			"description": {
				"ua": "Дерево. Ручна робота. Кожен індвиідуальний.",
				"en": "Wooden. Handcrafted. Each piece is unique."
			},
			"price": {
				"ua": 4500,
				"en": 125
			},
			"group": "OBJECT",
			"size": "15,5х8",
			"images": {
				"key": ""
			}
		},
		{
			"name": {
				"ua": "Автентичні сережки",
				"en": "Authentic earrings"
			},
			"group": "OBERIH",
			"size": "3x2",
			"price": {
				"ua": 3000,
				"en": 80
			},
			"variations": [{
				"material": "rhodium",
				"description": {
					"ua": "Срібло з покриттям родій. Матовий фініш. Річкові перли. Ручна робота. Кожна унікальна.",
					"en": "Silver with rhodium. Matte finish. River pearls. Handcrafted. Each piece is unique."
				},
				"image": {
					"key": ""
				}
			},{
				"material": "gold",
				"description": {
					"ua": "Срібло з покриттям золото 24К. Матовий фініш. Річкові перли. Ручна робота. Кожна унікальна.",
					"en": "Silver with gilding. Matte finish. River pearl. Handcrafted. Each piece is unique."
				},
				"image": {
					"key": ""
				}
			}]
		},
		{
			"group": "OBERIH",
			"name": {
				"ua": "Гребінь підвіс",
				"en": "Comb-pendant"
			},
			"variations": [{
				"material": "wood",
				"description": {
					"ua": "Дерево. В комплекті три вощених шнури: 1/2мм чорні, 1мм молочний. Ручна робота. Кожна унікальна.",
					"en": "Wood. Three waxed cords included: 1/2mm black, 1mm milky color. Handcrafted. Each piece is unique."
				},
				"price": {
					"ua": 3000,
					"en": 80
				},
				"size": "9,5x4",
				"image": {
					"key": ""
				}
			},{
				"material": "brass-nickel",
				"description": {
					"ua": "Латунь з покриттям нікель. В комплекті три вощених шнури: 1/2мм чорні, 1мм молочний. Ручна робота. Кожна унікальна.",
					"en": "Brass-nickel. Three waxed cords included: 1/2mm black, 1mm milky color. Handcrafted. Each piece is unique."
				},
				"price": {
					"ua": 6000,
					"en": 170
				},
				"size": "7х2,6",
				"image": {
					"key": ""
				}
			},{
				"material": "gold",
				"description": {
					"ua": "Латунь з покриттям золото 24К. В комплекті три вощених шнура: 1/2мм чорні, 1мм молочний. Ручна робота.",
					"en": "Brass with gliding 24K. Three waxed cords included: 1/2mm black, 1mm milky color. Handcrafted."
				},
				"price": {
					"ua": 6000,
					"en": 170
				},
				"size": "7х2,6",
				"image": {
					"key": ""
				}
			},{
				"material": "gold/wood imitation",
				"description": {
					"ua": "Латунь з покриттям золото 24К. В комплекті три вощених шнура: 1/2мм чорні, 1мм молочний. Ручна робота.",
					"en": "Brass with gliding 24K. Three waxed cords included: 1/2mm black, 1mm milky color. Handcrafted."
				},
				"price": {
					"ua": 6000,
					"en": 170
				},
				"size": "7х2,6",
				"image": {
					"key": ""
				}
			}]
		},
		{
			"group": "NATURE",
			"name": {
				"en": "Hair comb-pendant",
				"ua": "гребінь-підвіс"
			},
			"size": "6х7",
			"price": {
				"en": 170,
				"ua": 6000
			},
			"variations": [{
				"material": "brass-nickel",
				"description": {
					"en": "Brass-nickel. Three waxed cords included: 1/2mm black, 1mm milky color. Handcrafted. Each piece is unique.",
					"ua": "Латунь з нікелем. В комплекті три вощених шнура: 1/2мм чорні,1мм молочний.Ручна робота. Кожна унікальна."
				},
				"image": {
					"key": ""
				}
			}, {
				"material": "gold",
				"image": {
					"key": ""
				},
				"description": {
					"en": "Brass-gilding 24k. Three waxed cords included: 1/2mm black, 1mm milky color. Handcrafted. Each piece is unique.",
					"ua": "Латунь з позолотою 24k. В комплекті три вощених шнура: 1/2мм чорні, 1мм молочний. Ручна робота. Кожна унікальна."
				}
			}]
		},
		{
			"group": "NATURE",
			"size": "11х1,5 ",
			"name": {
				"en": "Seashell pendant *long spiral",
				"ua": "Підвіс мушля"
			},
			"description": {
				"en": "Wooden. Silver eyelet. Handcrafted. Each piece is unique.",
				"ua": "Дерево. Срібне вушко. Ручна робота. Кожна унікальна."
			},
			"material": "silver",
			"price": {
				"en": 60,
				"ua": 2300
			}
		},
		{
			"group": "OBJECT",
			"size": "15,5х8",
			"feature": "long spiral",
			"name": {
				"en": "hair comb",
				"ua": "гребінь для волося"
			},
			"description": {
				"en": "Wooden. Handcrafted. Each piece is unique.",
				"ua": "Дерево. Ручна робота. Кожен індвиідуальний."
			},
			"material": "silver",
			"price": {
				"en": 60,
				"ua": 2300
			}
		},
		{
			"group": "NATURE",
			"size": "1,5х1",
			"feature": "long spiral",
			"name": {
				"en": "hair comb",
				"ua": "гребінь для волося"
			},
			"description": {
				"en": "Baroque pearl. Silver eyelet. Three waxed cords included: 1/2mm black, 1mm milky color. Each piece is unique.",
				"ua": "Барочна перлина. Срібне вушко. Кожна унікальна. В комплекті три вощених шнура: 1/2мм чорні, 1мм молочний."
			},
			"material": "silver",
			"price": {
				"en": 85,
				"ua": 3200
			}
		},
		{
			"group": "NATURE",
			"size": "1,5х1",
			"name": {
				"en": "Swan comb",
				"ua": "гребінь лебідь"
			},
			"description": {
				"en": "Baroque pearl. Silver eyelet. Three waxed cords included: 1/2mm black, 1mm milky color. Each piece is unique.",
				"ua": "Барочна перлина. Срібне вушко. Кожна унікальна. В комплекті три вощених шнура: 1/2мм чорні, 1мм молочний."
			},
			"price": {
				"en": 170,
				"ua": 6000
			},
			"variations": [{
				"material": "silver",
				"image": {
					"key": ""
				}
			}, {
				"material": "gold",
				"image": {
					"key": ""
				}
			}]
		},
		{
			"group": "NATURE",
			"name": {
				"en": "seashell pendant",
				"ua": "підвіс мушля"
			},
			"size": "12х6",
			"variations": [{
				"material": "natural",
				"description": {
					"en": "Seashell. Silver eyelet. Each piece is unique.",
					"ua": "Натуральна мушля. Срібне вушко. Кожна унікальна."
				},
				"image": {
					"key": ""
				},
				"price": {
					"en": 85,
					"ua": 3200
				},
				"attachment": "1"
			}, {
				"material": "stone",
				"description": {
					"en": "Seashell. Silver eyelet. Jewelry stone. Each piece is unique.",
					"ua": "Натуральна мушля. Срібне вушко. Кожна унікальна."
				},
				"image": {
					"key": ""
				},
				"price": {
					"en": 100,
					"ua": 3700
				},
				"attachment": "2"
			}]
		},
		{
			"group": "NATURE",
			"name": {
				"en": "seashell set",
				"ua": "намисто з мушлями"
			},
			"description": {
				"en": "Seashells. Silver eyelet. Each piece is unique. ",
				"ua": "Натуральна мушля. Срібне вушко. Кожна унікальна"
			},
			"variations": [{
				"image": {
					"key": ""
				},
				"price": {
					"en": 350,
					"ua": 13000
				}
			}, {
				"image": {
					"key": ""
				},
				"price": {
					"en": 260,
					"ua": 10000
				}
			}, {
				"image": {
					"key": ""
				},
				"price": {
					"en": 230,
					"ua": 8600
				}
			}, {
				"image": {
					"key": ""
				},
				"price": {
					"en": 210,
					"ua": 8000
				}
			}, {
				"image": {
					"key": ""
				},
				"price": {
					"en": 230,
					"ua": 8600
				}
			}, {
				"image": {
					"key": ""
				},
				"price": {
					"en": 310,
					"ua": 11800
				}
			}, {
				"image": {
					"key": ""
				},
				"price": {
					"en": 315,
					"ua": 12000
				}
			}, {
				"image": {
					"key": ""
				},
				"price": {
					"en": 600,
					"ua": 23000
				}
			}, {
				"image": {
					"key": ""
				},
				"price": {
					"en": 800,
					"ua": 30000
				}
			}]
		}
	],


	"regular": [],
	"shell": []
}
